@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap');

* {
  font-family: 'Kanit', sans-serif;
}

/* body {
  background-color: #bdbdbd;
} */

.navbar {
  background-color: inherit;
}

.navbar-brand {
  font-weight: bold;
}

.navbar-nav .nav-link {
  margin-left: 1em;
}

.blurred-background {
  backdrop-filter: blur(10px); /* Adjust the blur value as needed */
  /* background-color: rgba(255, 255, 255, 0.5); Adjust the background color and opacity */
  padding: 20px; /* Add padding to your content to make it readable */
}

.container {
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #4c4c4c;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.color-red {
  color: red;
}

.card {
  border: none;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.card-title {
  font-size: 1.5rem;
}

.card-text {
  font-size: 1.2rem;
}

.comment-button,
.like-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.comment-button i,
.like-button i {
  font-size: 1.5rem;
}

.pre-line {
  white-space: pre-line;
}

/* Нижняя навигационная панель */
.navbar {
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
  width: 100%;
  /* background-color: #fff; */
  /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); */
  border-bottom: 1px solid #4c4c4c;
}

.nav-item {
  flex: 1;
  /* text-align: center; */
}

.nav-link {
  font-size: 1.5rem;
}

.nav-icon a {
  text-decoration: none;
  color: inherit;
}

.nav-border-top {
  border-top: 2px solid #4c4c4c;
}


/* Loading spinner */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 100px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4c4c4c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* End loading spinner */


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Input shake effect */
@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25%,
  75% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }
}

.shake-input {
  animation: shake 0.5s ease-in-out;
}

/* End input shake effect */


/* Google auth */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #aeaeae;
}
/* End google auth */


/* Post inputs */
#transparentInput {
  width: 100%; /* Ширина поля ввода */
  padding: 10px; /* Внутренний отступ для контента внутри поля ввода */
  border: none;
  border-radius: 5px;
  background-color: inherit;
  outline: none; /* Убираем контур вокруг поля ввода при фокусе */
}


.transparentTextArea {
  width: 100%; /* Ширина поля ввода */
  padding: 10px; /* Внутренний отступ для контента внутри поля ввода */
  border: none;
  border-radius: 5px;
  background-color: inherit; /* Прозрачный цвет фона */
  outline: none; /* Убираем контур вокруг поля ввода при фокусе */
  resize: auto;
}
/* End post inputs */


/* User settings */
.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
/* /User settings */


/* Switch buttons */
.custom-switch-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.custom-switch-label {
    margin-right: 10px; /* Adjust the margin as needed */
}
/* / Switch buttons */

/* Light */
@keyframes lightPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.8);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(255, 255, 0, 0.8);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.8);
  }
}

.lightbulb {
  border-radius: 50%;
  animation: lightPulse 2s;
}
/* /Light */